<template>
    <div id="container_step2" v-if="isCodeSuccess">
        <a target="_blank" v-if="finalAnswer != ''" :href="finalAnswer">CLICK ON ME...</a>

        <div v-if="finalAnswer == ''">
            <div class="title_step">
                <img src="../assets/Texte_etape_2.png" alt="ETAPE 2">
            </div>

            <div class="enigme_image" v-if="step == 0">
                <img src="../assets/1ceza5flVvJ0qwDW4I3P8b.png" alt="IMAGE 1">
            </div>
            <div class="enigme_image" v-if="step == 1">
                <img src="../assets/2g4hjwIMrqlD1OOyO24whs.png" alt="IMAGE 2">
            </div>
            <div class="enigme_image" v-if="step == 2">
                <img src="../assets/3lxE53kvv2sFxquqqHh8oh.png" alt="IMAGE 3">
            </div>

            <div class="input_answer" v-if="step < 3">
                <input type="text" v-model="answer">
                <button type="button" @click="verifyAnswer">OK</button>
            </div>
        </div>

    </div>
    <div v-else>
        <div class="title_step">
            <img src="../assets/Texte_etape1.png" alt="ETAPE 1">
        </div>

        <div class="input_answer">
            <input type="text" v-model="code">
            <button type="button" @click="verifyCode">OK</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return {
            step: 0,
            isCodeSuccess: false,
            answer: '',
            next_trial: '',
            code: '',
            finalAnswer: ''
        }
    },
    methods: {
        verifyAnswer(){
            axios.post("https://le19etype-api.fr/api/dtype/answer", {
                Answer : this.answer,
                Step: this.step
            }).then(res => {
                if(res.data.result){
                    this.step++;
                }
                if(res.data.answer){
                    this.finalAnswer = res.data.answer
                }
                this.answer = '';
            })
        },
        verifyCode(){
            axios.post("https://le19etype-api.fr/api/dtype/code", {
                Code : this.code
            }).then(res => {
                if(res.data){
                    this.isCodeSuccess = true;
                }
                this.code = '';
            })
        }
    }
}
</script>

<style lang="scss">
body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.title_step{
    font-size: 24px;
    text-align: center;
    text-decoration: underline;

    img{
        width: 300px;
    }
}

#container_step2{
    width: 100%;
    text-align: center;

    .enigme_image{
        img{
            width: 400px;
        }
    }

    a {
        color: #006699;
        text-decoration: unset;
        font-size: 20px;
        margin-bottom: 20px;
        font-family: 'HelpMe', sans-serif;
    }
}

.input_answer{
    background-image: url('../assets/Entourage_barre.png');
    background-repeat: no-repeat; 
    background-position: center;
    background-size: 30.5%;
    text-align: center;
    padding: 10px;

    input{
        padding: 10px;
        width: 10%;
        border-radius: 5px;
        border: 1px solid black;
        outline: none;
    }

    button{
        padding: 10px;
        margin-left: 5px;
        color: white;
        background-color: black;
        border: 1px solid black;
        cursor: pointer;

        &:hover{
            color: black;
            background-color: white;
        }
    }
}

</style>