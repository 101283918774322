<template>
    <div id="error_404">
        <img src="../assets/Erreur_PNG.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#error_404{
    text-align: center;
    img{
        width: 700px;
        
    }
}
</style>