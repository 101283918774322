<template>
    <div id="grid">
        <a target="_blank" v-if="answer != ''" :href="this.answer">CLICK ON ME...</a>
        <p class="text-final">{{ answer2 }}</p>
        <div v-if="answer == ''" class="grid-container">
            <div v-for="row in rows" :key="row" class="grid-row">
                <div :style="getCellStyle(row, col)" v-for="col in cols" :key="col" class="grid-cell"
                    @click="setColor((row - 1) * cols.length + col)">
                    {{ (row - 1) * cols.length + col }}
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            rows: Array.from({ length: 16 }, (_, i) => i + 1),
            cols: Array.from({ length: 16 }, (_, i) => i + 1),
            index: 0,
            tabRed: null,
            tabGreen: null,
            tabBlue: null,
            answer: '',
            answer2: ''
        }
    },
    methods: {
        setColor(clickedCell) {
            switch (this.index) {
                case 0:
                    this.tabRed = clickedCell
                    this.index++
                    break
                case 1:
                    this.tabGreen = clickedCell
                    this.index++
                    break
                case 2:
                    this.tabBlue = clickedCell
                    this.index = 0
                    break
            }

            if (this.tabRed != null && this.tabGreen != null && this.tabBlue != null) {
                axios.post("https://le19etype-api.fr/api/dtype/grid", {
                    RedIndex: this.tabRed,
                    GreenIndex: this.tabGreen,
                    BlueIndex: this.tabBlue
                }).then(response => {
                    if (response.data.result) {
                        this.answer = response.data.answer
                        this.answer2 = response.data.answer2
                    }
                })
            }
        },
        getCellStyle(row, col) {
            const cellNumber = (row - 1) * this.cols.length + col;
            let backgroundColor = 'white'
            let color = ''
            if (cellNumber == this.tabRed) {
                backgroundColor = 'red'
                color = 'white'
            } else if (cellNumber == this.tabGreen) {
                backgroundColor = 'green'
                color = 'white'
            } else if (cellNumber == this.tabBlue) {
                backgroundColor = 'blue'
                color = 'white'
            } else {
                switch ((row - 1) % 3) {
                    case 0:
                        color = '#128694';
                        break;
                    case 1:
                        color = '#4D7669';
                        break;
                    case 2:
                        color = '#006699';
                        break;
                }
            }

            return {
                backgroundColor,
                color
            };
        }
    }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'HelpMe';
    src: url('../assets/Eazy Chat.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.text-final {
    font-family: 'HelpMe', sans-serif;
    font-size: 32px;
    text-align: center;
}

#grid{
    text-align: center;

    a {
        color: #006699;
        text-decoration: unset;
        font-size: 20px;
        margin-bottom: 20px;
        font-family: 'HelpMe', sans-serif;
    }
}

.grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'HelpMe', sans-serif;
}

.grid-row {
    display: flex;
}

.grid-cell {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    background-color: white;
    font-size: 20px;

    &:hover {
        background-color: black;
        color: white;
    }
}
</style>