<template>
    <div id="coord-container">
        <p class="hidden">Let's explore the World!</p>
        <p class="text-final" v-if="message != ''">{{ message }}</p>
        <p class="text-final" v-if="message != ''">{{ message2 }}</p>
        <p class="text-coord" v-if="message == ''">
            Bien che6rs eX-EnKeturs, le DiJ-neuvieme typeZ vous invLite a cherVher dLan a traBers LLe monde, Facile, noW
            !
        </p>

        <div class="container-lines" v-if="message == ''">
            <div class="line-coord">
                <img class="icone1" src="../assets/haut.png" />
                <div class="numbers">
                    <img @click="sign1 = '+'; SendResponse();" v-if="sign1 == '-'" class="icone2" src="../assets/-.png" />
                    <img @click="sign1 = '-'; SendResponse();" v-else class="icone2" src="../assets/+.png" />
                    <div>
                        <img @click="increaseVal(1, 1)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj1.val1 }}</span>
                        <img @click="decreaseVal(1, 1)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <div>
                        <img @click="increaseVal(1, 2)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj1.val2 }}</span>
                        <img @click="decreaseVal(1, 2)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <div>
                        <img @click="increaseVal(1, 3)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj1.val3 }}</span>
                        <img @click="decreaseVal(1, 3)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <span class="virgule">,</span>
                    <div>
                        <img @click="increaseVal(1, 4)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj1.val4 }}</span>
                        <img @click="decreaseVal(1, 4)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <div>
                        <img @click="increaseVal(1, 5)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj1.val5 }}</span>
                        <img @click="decreaseVal(1, 5)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <img v-if="val1Success" src="../assets/Cadenas_Open_v2.png" alt="">
                    <img v-else src="../assets/Cadenas_Closed_V2.png" alt="">
                </div>
            </div>
            <div class="line-coord">
                <img class="icone1" src="../assets/bas.png" />
                <div class="numbers">
                    <img @click="sign2 = '+'" v-if="sign2 == '-'" class="icone2" src="../assets/-.png" />
                    <img @click="sign2 = '-'" v-else class="icone2" src="../assets/+.png" />
                    <div>
                        <img @click="increaseVal(2, 1)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj2.val1 }}</span>
                        <img @click="decreaseVal(2, 1)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <div>
                        <img @click="increaseVal(2, 2)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj2.val2 }}</span>
                        <img @click="decreaseVal(2, 2)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <div>
                        <img @click="increaseVal(2, 3)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj2.val3 }}</span>
                        <img @click="decreaseVal(2, 3)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <span class="virgule">,</span>
                    <div>
                        <img @click="increaseVal(2, 4)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj2.val4 }}</span>
                        <img @click="decreaseVal(2, 4)" class="little-icon" src="../assets/-g.png" alt="">
                    </div>
                    <div>
                        <img @click="increaseVal(2, 5)" class="little-icon" src="../assets/g.png" alt="">
                        <span>{{ obj2.val5 }}</span>
                        <img @click="decreaseVal(2, 6)" class="little-icon" src="../assets/-g.png" alt="">
                    </div> 
                    <img v-if="val2Success" src="../assets/Cadenas_Open_v2.png" alt="">
                    <img v-else src="../assets/Cadenas_Closed_V2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            obj1: {
                val1: 0,
                val2: 0,
                val3: 0,
                val4: 0,
                val5: 0
            },
            obj2: {
                val1: 0,
                val2: 0,
                val3: 0,
                val4: 0,
                val5: 0
            },
            sign1: '-',
            sign2: '-',
            val1Success: false,
            val2Success: false,
            message: '',
            message2: '',
        }
    },
    methods: {
        SendResponse() {
            axios.post("https://le19etype-api.fr/api/dtype/coords", {
                Coord1: this.sign1 + this.obj1.val1 + this.obj1.val2 + this.obj1.val3 + "," + this.obj1.val4 + this.obj1.val5,
                Coord2: this.sign2 + this.obj2.val1 + this.obj2.val2 + this.obj2.val3 + "," + this.obj2.val4 + this.obj2.val5
            }).then(response => {
                this.val1Success = response.data.success1
                this.val2Success = response.data.success2
                if (this.val1Success && this.val2Success) {
                    this.message = response.data.message
                    this.message2 = response.data.message2
                }
            })
        },
        increaseVal(index1, index2) {
            if (index1 == 1) {
                switch (index2) {
                    case 1:
                        if (this.obj1.val1 < 9)
                            this.obj1.val1++
                        break
                    case 2:
                        if (this.obj1.val2 < 9)
                            this.obj1.val2++
                        break
                    case 3:
                        if (this.obj1.val3 < 9)
                            this.obj1.val3++
                        break
                    case 4:
                        if (this.obj1.val4 < 9)
                            this.obj1.val4++
                        break
                    case 5:
                        if (this.obj1.val5 < 9)
                            this.obj1.val5++
                        break
                }
            } else {
                switch (index2) {
                    case 1:
                        if (this.obj2.val1 < 9)
                            this.obj2.val1++
                        break
                    case 2:
                        if (this.obj2.val2 < 9)
                            this.obj2.val2++
                        break
                    case 3:
                        if (this.obj2.val3 < 9)
                            this.obj2.val3++
                        break
                    case 4:
                        if (this.obj2.val4 < 9)
                            this.obj2.val4++
                        break
                    case 5:
                        if (this.obj2.val5 < 9)
                            this.obj2.val5++
                        break
                }
            }
            this.SendResponse();
        },
        decreaseVal(index1, index2) {
            if (index1 == 1) {
                switch (index2) {
                    case 1:
                        if (this.obj1.val1 > 0)
                            this.obj1.val1--
                        break
                    case 2:
                        if (this.obj1.val2 > 0)
                            this.obj1.val2--
                        break
                    case 3:
                        if (this.obj1.val3 > 0)
                            this.obj1.val3--
                        break
                    case 4:
                        if (this.obj1.val4 > 0)
                            this.obj1.val4--
                        break
                    case 5:
                        if (this.obj1.val5 > 0)
                            this.obj1.val5--
                        break
                }
            } else {
                switch (index2) {
                    case 1:
                        if (this.obj2.val1 > 0)
                            this.obj2.val1--
                        break
                    case 2:
                        if (this.obj2.val2 > 0)
                            this.obj2.val2--
                        break
                    case 3:
                        if (this.obj2.val3 > 0)
                            this.obj2.val3--
                        break
                    case 4:
                        if (this.obj2.val4 > 0)
                            this.obj2.val4--
                        break
                    case 5:
                        if (this.obj2.val5 > 0)
                            this.obj2.val5--
                        break
                }
            }
            this.SendResponse();
        }
    }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'HelpMe';
    src: url('../assets/Eazy Chat.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#coord-container {
    .hidden{
        display: none;
    }

    .text-final{
        font-family: 'HelpMe', sans-serif;
        font-size: 32px;
        text-align: center;
    }

    .text-coord {
        font-family: 'HelpMe', sans-serif;
        font-size: 32px;
        width: 30%;
        margin: 100px auto;
    }

    .icone1 {
        width: 340px;
    }

    .container-lines {
        margin: auto;
        width: 38%;


        .line-coord {
            display: flex;
            margin-bottom: 20px;

            .numbers {
                padding: 10px;
                background-color: #292928;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 50px;
                display: flex;

                img {
                    width: 100px;
                    height: 100px;
                    margin: auto;
                }

                .icone2 {
                    cursor: pointer;
                }

                .little-icon {
                    width: 30px;
                    display: block;
                    margin: 10px auto;
                    height: unset;
                    cursor: pointer;
                }

                .virgule {
                    height: 53px;
                    margin: auto;
                }
            }

            img {
                vertical-align: middle;
            }

            span {
                margin: 3px;
                background-color: #8f8e8e;
                font-family: 'HelpMe', sans-serif;
                color: #006699;
                font-size: 50px;
                vertical-align: middle;
                padding: 5px;
                border: 3px solid #dacfcf;
            }
        }
    }
}
</style>