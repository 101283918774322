import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/19eType.vue'
import Codex from '@/components/Codex.vue'
import NotFound from '@/components/404.vue'
import Grille from '@/components/Grille.vue'
import Coordonates from '@/components/Coordonates.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/6C0277',
    name : 'grille',
    component: Grille
  },
  {
    path: '/bpRRnbJjCE',
    name : 'codex',
    component: Codex
  },
  {
    path: '/UOVLnQAycx',
    name: 'coordonates',
    component: Coordonates
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
