<template>
    <div id="container_codex">
        <p class="final-answer" v-if="finalAnswer != ''">{{ finalAnswer }}</p>
        <div class="line_codex">
            <div>
                <img @click="verifyCodex(1)" class="sign" src="../assets/Bouton_plus.png" alt="BTN +">
            </div>
            <div>
                <img @click="verifyCodex(2)" class="sign" src="../assets/Bouton_plus.png" alt="BTN +">
            </div>
            <div>
                <img @click="verifyCodex(3)" class="sign" src="../assets/Bouton_plus.png" alt="BTN +">
            </div>
            <div>
                <img @click="verifyCodex(4)" class="sign" src="../assets/Bouton_plus.png" alt="BTN +">
            </div>
            <div>
                <img @click="verifyCodex(5)" class="sign" src="../assets/Bouton_plus.png" alt="BTN +">
            </div>
        </div>
        <div class="line_codex">
            <div>
                <img class="element" src="../assets/Bouton_AT.png" alt="BTN -">
            </div>
            <div>
                <img class="element" src="../assets/Bouton_DE.png" alt="BTN -">
            </div>
            <div>
                <img class="element" src="../assets/Bouton_AS.png" alt="BTN -">
            </div>
            <div>
                <img class="element" src="../assets/Bouton_DS.png" alt="BTN -">
            </div>
            <div>
                <img class="element" src="../assets/Bouton_VI.png" alt="BTN -">
            </div>
        </div>
        <div class="line_codex">
            <div>
                <img @click="verifyCodex(6)" class="sign" src="../assets/Bouton_moins.png" alt="BTN -">
            </div>
            <div>
                <img @click="verifyCodex(7)" class="sign" src="../assets/Bouton_moins.png" alt="BTN -">
            </div>
            <div>
                <img @click="verifyCodex(8)" class="sign" src="../assets/Bouton_moins.png" alt="BTN -">
            </div>
            <div>
                <img @click="verifyCodex(9)" class="sign" src="../assets/Bouton_moins.png" alt="BTN -">
            </div>
            <div>
                <img @click="verifyCodex(10)" class="sign" src="../assets/Bouton_moins.png" alt="BTN -">
            </div>
        </div>
        <div class="successClass" v-if="success">
            <img src="../assets/Check.png" alt="YES">
        </div>
        <div class="successClass" v-else>
            <img src="../assets/Cross.png" alt="NO">
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            step: 0,
            success: false,
            finalAnswer: ''
        }
    },
    methods:{
        verifyCodex(number){
            axios.post("https://le19etype-api.fr/api/dtype/codex", {
                Step: this.step,
                NumberArrow: number
            }).then(res =>{
                if(res.data.isSuccessful){
                    this.step++;
                    this.success = true;
                    if(res.data.isFinished){
                        this.$router.push(res.data.answer)
                    }
                } else {
                    this.step = 0;
                    this.success = false;
                }
            })
        }
    }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'HelpMe';
    src: url('../assets/Eazy Chat.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#container_codex{
    width: 80%;
    margin-left: 10%;

    .final-answer{
        font-family: 'HelpMe', sans-serif;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        width: 50%;
        margin: auto;
    }

    .successClass{
        margin-top: 10%;
        text-align: center;

        img{
            width: 150px;
        }
    }

    .line_codex{
        display: flex;
        justify-content: space-between;
        
        div{
            text-align: center;
            flex: 1;
            padding: 10px;

            .sign{
                width: 50px;
                cursor: pointer;
            }
            
            .element{
                width: 100px;
            }
        }
    }
}
</style>