<template>
  <router-view/>
  <div class="sidebar"></div>
  <div class="logo_hp"></div>
</template>

<style lang="scss">
body{
  background-image: url("./assets/Fond.png");
  background-size: cover;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  background-position: center; 

  margin: 0;
  padding: 0;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 11%; 
  height: 100%;
  background-image: url('./assets/Bare_Laterale.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.logo_hp{
  position: fixed;
  left: 10px;
  top: 10px;
  height: 250px;
  width: 200px;
  background-image: url('./assets/Logo_Vanilla.png');
  background-size: 200px;
  background-repeat: no-repeat;
}

#app{
  height: 100vh;
  align-items: center;
  align-content: center;
}
</style>
